import React, { useEffect } from 'react';
import './TopBar.scss';
import { useGetOAuthToken } from '../../services/auth/mutations';

interface ITopBarProps {
  // define your props here
}

const TopBar: React.FC<ITopBarProps> = (props) => {
  const { execute: getOAuthToken } = useGetOAuthToken();

  const onCreateNewBorrowerLoan = () => {
    getOAuthToken()
      .then(({ data }) => {
        if (data) {
          const oAuthToken = data.getOAuthToken;
          window.location.href = `https://sbo.constlending.com/?internal=true&oauth=${oAuthToken}`;
        }
      })
  }

  return (
    <div className="top-bar">
      <div className='logo'>
        <ConstlendingLogo />
      </div>
      <div className="internal">
        INTERNAL
      </div>
      <div className='button-blue-outlined' onClick={onCreateNewBorrowerLoan}>
        Create New Borrower Loan
      </div>
    </div>
  );
};

const ConstlendingLogo: React.FC = () => (
  <div className="constlending-logo">
    <img className="constlending-logo-blue" src="/assets/icons/constlending-blue.svg" alt="logo" />
    <img className="constlending-logo-text" src="/assets/icons/constlending-text.svg" alt="logo" />
  </div>
)

export default TopBar;