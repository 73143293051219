import { useContext, useEffect, useState } from 'react';
import { Button, Checkbox, CheckboxProps, DatePicker, Dropdown, Form, MenuProps } from 'antd';
import { useForm } from 'antd/es/form/Form';

import leafPng from 'assets/images/leaf.png';
import ModalTask from '../../../../../components/BorrowerTask/components/Organisms/Modals/ModalTask/ModalTask';
import { LoanContext } from '../LoanDetails';
import './CreateLoanDoc.scss';
import InputBorderNone from '../../../../../components/BorrowerTask/components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import CardUploadResponse from '../../../../../components/BorrowerTask/components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { useCreateLoanDoc, useDeleteFileLoanDoc } from '../../../../../services/loan/mutations';
import { LoanDocState } from '../../../../../gql/graphql';
import moment from 'moment';
import { useGetTasksByLoan } from '../../../../../services/task/querys';
import DropdownBasic from '../../../../../components/Dropdowns/DropdownBasic/DropdownBasic';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import CheckboxBasic from '../../../../../components/Atoms/Checkboxs/CheckboxBasic/CheckboxBasic';

interface Props {
  selectLoanDoc?: any;
  loanDocs?: any[];
  setSelectLoanDoc: Function;
}

const CreateLoanDoc = ({ selectLoanDoc, setSelectLoanDoc, loanDocs }: Props) => {
  const [form] = useForm();
  const loanSelected: any = useContext(LoanContext);

  const { execute: deleteFileLoanDoc } = useDeleteFileLoanDoc();
  const { reexecute: getLoanDocByLoan } = useGetLoanDocByLoan({ variables: { loanId: loanSelected?._id } });
  const { reexecute: getTasksByLoan } = useGetTasksByLoan({
    variables: { loanId: loanSelected?._id },
    pause: true
  });
  const { execute: createLoanDoc, fetching } = useCreateLoanDoc();

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [statusLoanDoc, setStatusLoanDoc] = useState<string>();

  useEffect(() => {
    if (!isModalTaskVisible) {
      setStatusLoanDoc(undefined);
      form.resetFields();
    }
  }, [isModalTaskVisible])

  useEffect(() => {
    if (selectLoanDoc) {
      setModalTaskVisible(true);

      form.setFieldValue("name", selectLoanDoc.label);

      const isLoanDoc = loanDocs?.find(item =>
        item.key === selectLoanDoc?.key || item._id === selectLoanDoc?._id
      );

      if (isLoanDoc?.expiration)
        form.setFieldValue("expiration", moment(isLoanDoc?.expiration));

      if (isLoanDoc?.state)
        setStatusLoanDoc(isLoanDoc.state);
    }
  }, [selectLoanDoc]);

  const onFinish = async (file: any) => {
    const isLoanDoc = loanDocs?.find(item => item.key === selectLoanDoc?.key);

    await createLoanDoc({
      ...form.getFieldsValue(),
      _id: isLoanDoc?._id,
      key: selectLoanDoc?.key,
      file: file[0],
      loan: loanSelected._id,
      state: statusLoanDoc || LoanDocState.Pending
    });

    if (!selectLoanDoc) {
      form.resetFields();
      setModalTaskVisible(false);
    }

    getTasksByLoan({ requestPolicy: 'network-only' });
  }

  const handlerOpenModal = (action: any) => {
    action(true)
    setSelectLoanDoc();
    form.resetFields();
  }

  const handlerSelectStatus = async (value: string) => {
    setStatusLoanDoc(value);

    const isLoanDoc = loanDocs?.find(item => item.key === selectLoanDoc?.key);

    await createLoanDoc({
      ...form.getFieldsValue(),
      _id: isLoanDoc?._id,
      key: selectLoanDoc?.key,
      loan: loanSelected._id,
      state: value
    });

    getLoanDocByLoan({ requestPolicy: 'network-only' });
    getTasksByLoan({ requestPolicy: 'network-only' });
  }

  const onDeleteFile = () => {
    if (isLoanDoc && isLoanDoc.file)
      deleteFileLoanDoc({ loanDocId: isLoanDoc._id, fileKey: isLoanDoc.file.key })
  }

  const onHideDoc: CheckboxProps['onChange'] = async (e) => {
    const isLoanDoc = loanDocs?.find(item => item.key === selectLoanDoc?.key);

    await createLoanDoc({
      ...form.getFieldsValue(),
      _id: isLoanDoc?._id,
      key: selectLoanDoc?.key,
      loan: loanSelected._id,
      hidden: e.target.checked,
      state: statusLoanDoc || LoanDocState.Pending
    });

    getLoanDocByLoan({ requestPolicy: 'network-only' });
    getTasksByLoan({ requestPolicy: 'network-only' });
  };

  const isLoanDoc = loanDocs?.find(item => item.key === selectLoanDoc?.key || item._id === selectLoanDoc?._id);

  const status = [
    { key: 'ORDERED', label: "Appraisal ordered" },
    { key: 'RECEIVED', label: "Appraisal received" },
    { key: 'APPROVED', label: "Appraisal approved" },
  ];

  const checkDisabled = [
    'credit_report',
    'background_check',
  ];

  const hiddenCheckboxDisabled = [
    'term_sheet',
    'pre_approval'
  ];

  let fileLoanDoc = isLoanDoc && isLoanDoc?.file ? [isLoanDoc?.file] : undefined;

  if (!fileLoanDoc && selectLoanDoc && loanSelected.onboarding) {
    let docKeyOnboarding: any = {
      'term_sheet': 'termSheetURL',
      'pre_approval': 'preApprovalLetterUrl'
    };

    const preUrl = loanSelected.onboarding[docKeyOnboarding[selectLoanDoc?.key]];
    
    if (preUrl) {
      const urlSplit = preUrl.split('/')
      fileLoanDoc = [{
        key: urlSplit[urlSplit.length - 1],
        name: urlSplit[urlSplit.length - 1],
        url: preUrl,
      }];
    }

  }

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      renderButtonTrigger={(openModal: any) => (
        <div className='action-button' onClick={() => handlerOpenModal(openModal)} style={{ cursor: 'pointer' }}>
          Upload loan document
        </div>
      )}
      header={{
        title: <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastName} | {loanSelected.name}</span>,
        actionLeft: (<img src={leafPng} alt="icon" className="paperPng" />)
      }}
      animationDirection="onRight"
      dots={false}
      stepsTotal={1}
      currentStep={1}
      setStep={() => { }}
      footer={<></>}
    >
      <div className='CreateLoanDoc'>
        <section>
          {/*<h2 className="h2">
            Upload a loan document for{' '}
            <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastName} {loanSelected.type}</span>
            {' '}loan in {loanSelected.name}
          </h2>*/}
          <h2 className="h2">What is the status of {selectLoanDoc?.label}?</h2>

          {selectLoanDoc && selectLoanDoc.key !== 'term_sheet' && selectLoanDoc.key !== 'pre_approval' &&
            <DropdownBasic
              className='mt-20'
              label="Select status"
              items={status}
              selected={statusLoanDoc}
              onClickSelect={handlerSelectStatus}
            />
          }

          <Form
            form={form}
            name="basic"
            className='form'
            autoComplete="off"
            onFinish={onFinish}
          >
            <CardUploadResponse
              title=""
              titleLocation='outside'
              limitFiles={1}
              actionDeleteFile={!hiddenCheckboxDisabled.includes(selectLoanDoc?.key) && onDeleteFile}
              files={fileLoanDoc}
              submit={{
                title: 'Submit request',
                loading: fetching,
                modalConfirm: true,
                confirmDescription:
                  "After submitting, you'll have to contact us to make any changes.",
                onClick: (files) => onFinish(files),
              }}
              insertHtml={
                <div className='doc-info'>
                  <Form.Item
                    name="name"
                    className='form-item-doc-name'
                    style={{ marginBottom: 0 }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your doc name!'
                      }
                    ]}
                  >
                    <InputBorderNone
                      placeholder="Enter doc name"
                      className="form-task-name"
                      color="bold"
                      disabled={selectLoanDoc}
                    />
                  </Form.Item>

                  {!selectLoanDoc &&
                    <Form.Item
                      name="description"
                      style={{ marginBottom: 15 }}
                    >
                      <InputBorderNone
                        placeholder="Enter document description (optional)"
                        className="form-doc-description"
                      />
                    </Form.Item>
                  }

                  {!hiddenCheckboxDisabled.includes(selectLoanDoc?.key) &&
                    <Form.Item
                      name="expiration"
                      style={{ marginTop: 20 }}
                    >
                      <DatePicker
                        className="form-doc-expiration"
                        placeholder="Expiration (optional)"
                      />
                    </Form.Item>
                  }

                </div>
              }
            />
          </Form>
          <div className='hide-doc'>
            <CheckboxBasic
              defaultChecked={checkDisabled.includes(selectLoanDoc?.key) || isLoanDoc?.hidden}
              onChange={onHideDoc}
              label='Hide this doc from the borrower'
              disabled={hiddenCheckboxDisabled.includes(selectLoanDoc?.key) || checkDisabled.includes(selectLoanDoc?.key)}
            />
          </div>
        </section>
      </div>
    </ModalTask>
  );
};
export default CreateLoanDoc;