import React, { createContext, useContext, useEffect, useState } from 'react';

import { BellIcon, EyeIcon, TrashIcon } from '../../../../../components/BorrowerTask/BorrowerTasks';
import { StatusBadge } from '../../LoanList';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext, SelectLoanDocumentContext } from '../LoanDetails';
import { Loan, LoanDoc, LoanDocState, Task, TaskState } from '../../../../../gql/graphql';
import CreateLoanDoc from './CreateLoanDoc';
import iconDonwloadSvg from 'assets/images/icon-download.svg';
import { useCreateLoanDoc } from '../../../../../services/loan/mutations';

const LoanDocumentsStatic = [
  { key: 'term_sheet', label: 'Term Sheet' },
  { key: 'appraisal', label: 'Appraisal' },
  { key: 'pre_approval', label: 'Pre-approval letter' },
  { key: 'title', label: 'Title' },
  { key: 'insurance', label: 'Insurance Documents' },
  { key: 'credit_report', label: 'Credit Report' },
  { key: 'background_check', label: 'Background Check' },
  { key: 'closing_docs', label: 'Closing docs' },
];

interface ILoanDocumentsProps {
  tasks: Task[];
}

const LoanDocuments: React.FC<ILoanDocumentsProps> = (props) => {

  const loan: Loan | any = useContext(LoanContext);
  const { loanDocKey, setLoanDocKey }: any = useContext(SelectLoanDocumentContext);

  const { execute: createLoanDoc, fetching } = useCreateLoanDoc();
  const { data: loanDocs, reexecute: getLoanDocByLoan } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const newLoanDocs = loanDocs?.filter((item) => !item.key);
  const [selectLoanDoc, setSelectLoanDoc] = useState<any>();

  useEffect(() => {
    if (loanDocKey) {
      const isLoanDoc = LoanDocumentsStatic.find(item => item.key === loanDocKey);
      if (isLoanDoc)
        setSelectLoanDoc({ key: isLoanDoc.key, label: isLoanDoc.label });
      setLoanDocKey();
    }
  }, [loanDocKey])

  const getStateLoanDoc = (loanDoc: LoanDoc) => {
    const keyRefLoanDoc = ['appraisal'];
    const keyRefTask = ['pay_appraisal'];
    let taskRef;

    if (keyRefLoanDoc.includes(loanDoc.key as string)) {
      const indexKey = keyRefLoanDoc.findIndex(item => item === loanDoc.key);
      taskRef = props.tasks.find(task => task.key === keyRefTask[indexKey]);
    }
    
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.key)
    
    if ((
      !isLoanDoc || isLoanDoc?.state === LoanDocState.Blocking) && 
      (taskRef?.state === TaskState.Approved || taskRef?.state === TaskState.Review)
    ) {
      return 'UPLOAD DOCUMENT';
    } else if(isLoanDoc?.state === LoanDocState.Blocking) {
      return 'BLOCKED';
    } else if (
      isLoanDoc?.state === LoanDocState.Received || 
      isLoanDoc?.state === LoanDocState.Ordered
    ) {
      return 'VERIFY/UPDATE STATUS';
    } else if (isLoanDoc?.state === LoanDocState.Approved) {
      return 'COMPLETE';
    } 
    return !isLoanDoc ? 'AWAITING BORROWER' : 'UPLOAD DOCUMENT';
  }

  const handlerOnBlocking = async (loanDoc: any) => {
    const isLoanDoc = loanDocs?.find(item => item.key === loanDoc.docKey);

    await createLoanDoc({
      _id: isLoanDoc?._id,
      key: loanDoc.docKey,
      name: loanDoc.label,
      loan: loan._id,
      state: LoanDocState.Blocking
    });

    getLoanDocByLoan({ requestPolicy: 'network-only' });
  }

  return (
    <div className='loan-documents'>
      <div className='title'>
        Loan Documents
      </div>
      <CreateLoanDoc selectLoanDoc={selectLoanDoc} setSelectLoanDoc={setSelectLoanDoc} loanDocs={loanDocs || []} />
      <div className='loan-documents-list'>
        {LoanDocumentsStatic.map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docKey={loanDoc.key}
            label={loanDoc.label}
            onSelect={setSelectLoanDoc}
            state={getStateLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
          />
        ))}
        {newLoanDocs?.map((loanDoc: any, index) => (
          <DocumentItem
            key={index}
            docId={loanDoc._id}
            label={loanDoc.name}
            onSelect={setSelectLoanDoc}
            state={getStateLoanDoc(loanDoc)}
            handlerOnBlocking={handlerOnBlocking}
          />
        ))}
      </div>
    </div>
  );
};

interface IDocumentItemProps {
  label: string;
  docId?: string;
  docKey?: string;
  onSelect: Function;
  state: "COMPLETE" | 'UPLOAD DOCUMENT' | 'AWAITING BORROWER' | "VERIFY/UPDATE STATUS" | "BLOCKED";
  handlerOnBlocking: Function;
}

const DocumentItem: React.FC<IDocumentItemProps> = (props) => {
  const { label, docKey, docId, state, onSelect } = props;
  
  const isActionBlocked = state === 'AWAITING BORROWER' || state === 'BLOCKED';

  const onBlocking = () => {
    if(isActionBlocked) 
      props.handlerOnBlocking(props);
  }

  return (
    <div className={`document-item document-item-${state}`}>
      <div className='actions-container'>
        {/*<EyeIcon />*/}
        <img
          className="cursor"
          alt="download"
          src={iconDonwloadSvg}
        />
      </div>
      <div className='label' onClick={() => onSelect({ _id: docId, key: docKey, label })}>
        {label}
      </div>
      <div className='feedback-actions-container'>
        <div 
          onClick={onBlocking} 
          className={`action-blocked ${!isActionBlocked && 'action-blocked-disabled'} ${state === 'BLOCKED' && 'action-blocked-blocked'}`}
        >
          <BellIcon/>
        </div>
        {/*<TrashIcon />*/}
      </div>
      <div onClick={() => onSelect({ _id: docId, key: docKey, label })}>
        <StatusBadge title={state === 'BLOCKED'? 'AWAITING BORROWER': state} />
      </div>
    </div>
  );
};

export default LoanDocuments;