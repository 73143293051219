import { gql } from "urql";

export const GET_LOAN_BY_OWNER: any = gql(`
    query GetLoanByOwner{
        GetLoanByOwner {
            _id
            name
            type
            purpose
            ltv
            owner {
                _id
                name
                lastname
            }
            investmentExperience
        }  
    }
`)

export const GET_LOAN_BY_ID: any = gql(`
    query GetLoanById($id: String!){
        GetLoanById(id: $id) {
            _id
            name
        }  
    }
`)

export const GET_LOANS: any = gql(`
    query GetLoans{
        GetLoans {
            _id
            name
            progress
            type
            purpose
            ltv
            investmentExperience
            tasks {
                _id
                key
                name
                state
            }
            owner {
                _id
                name
                lastName
                email
                phone
                avatar 
                loans {
                    _id
                    name
                }
            }
        }  
    }
`)

export const GET_LOAN_DOCUMENTS: any = gql(`
    query GetLoanDocByLoan($loanId: String!){
        GetLoanDocByLoan(loanId: $loanId) {
            _id
            key
            name
            description
            expiration
            state
            hidden
            file {
                key
                name
                url
            }
        }  
    }
`)

export const DOWNLOAD_FILES_PENDING: any = gql(`
    query DownloadFilesPending($loanId: String!, $taskId: String){
        DownloadFilesPending(loanId: $loanId, taskId: $taskId) {
            key
            name
            url
            task {
                _id
                name
            }
            taskResponse {
                key
                label
            }
        }  
    }
`)
