import React, { useContext, useEffect, useState } from 'react';
import { TaskContext } from '../TitleCompany';
import { Button, Form } from 'antd';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { ArrowRightOutlined } from '@ant-design/icons';
import ModalFinish from './ModalFinish';
import { Task, TaskResponse, TaskState } from '../../../../gql/graphql';
import { useCreateResponseTask } from '../../../../services/task/mutations';
import SectionIssues from '../../../SectionIssues/SectionIssues';
import InputBorderNone from '../../components/Atoms/Inputs/InputBorderNone/InputBorderNone';
import ButtonSubmit from '../../components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import { formatterPhone as utilsFormatterPhone } from '../../../../utils/formatters/formatter'
import ActionAcceptAndReject from '../../../ActionAcceptAndReject/ActionAcceptAndReject';
import StateTag from '../../components/Atoms/Tags/StateTag/StateTag';

interface IForm {
  titleCompany: string;
  name: string;
  phone: string;
  email: string;
}

interface Props {
  setStep: Function;
  closeTask: Function;
}

const Step1 = ({ setStep, closeTask }: Props) => {
  const [form] = Form.useForm();
  const task: Task | any = useContext(TaskContext);

  const { data: taskResponses, reexecute: getTaskResponses } =
    useGetTaskResponsesByTask({
      variables: { findResponsesByTask: { taskId: task._id } }
    });
  const { execute: createResponseTask, fetching } = useCreateResponseTask();

  const [modalFinish, setModalFinish] = useState<boolean>(false);
  const [response, setResponse] = useState<TaskResponse>();
  const [taskCompleted, setTaskCompleted] = useState<boolean>();

  useEffect(() => {
    if (response)
      form.setFieldsValue(response.metadata);
  }, [response])

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setTaskCompleted(_response?.state === TaskState.Approved);
  }, [taskResponses]);

  const formatterPhone = (e: any) => {
    form.setFieldValue('phone', utilsFormatterPhone(e.target.value));
  };

  const onFinish = async (values: IForm) => {
    await createResponseTask({
      task: task._id,
      key: `contact-information`,
      label: `Contact information for the property’s title company/agent`,
      step: 1,
      metadata: values,
      state: TaskState.Review
    });

    getTaskResponses({ requestPolicy: 'network-only' });
    setModalFinish(true);
  }

  return (
    <section>
      <div className="section-1">
        <h3 className="h3">
          Enter the contact information for the property’s title company/agent
          so we can order a title search
        </h3>

        <SectionIssues task={task} />

        <div className="mt-40" style={{ position: 'relative' }}>
          {response &&
            <ActionAcceptAndReject
              taskResponseId={response?._id}
              task={{ id: task._id, key: task.key, name: task.name }}
              files={response?.files}
              currentState={response?.state}
            />
          }
          <div className={`title-company-step-1 title-company-step-1-${response?.state}`}>

            {response?.state && response?.state !== TaskState.Review && <StateTag state={response?.state} />}
            {response?.state && response?.state === TaskState.Review && <StateTag state={response?.state} isLabelLocked />}

            <Form
              name="edit"
              autoComplete="off"
              requiredMark="optional"
              colon={false}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                label=""
                name="titleCompany"
                className="titleCompany"
                rules={[{ required: true, message: '' }]}
              >
                <InputBorderNone
                  placeholder="Title company name"
                  className="bold"
                />
              </Form.Item>
              <Form.Item
                label="Contact name (if different from lender)"
                name="name"
                className="name"
                rules={[{ required: true, message: '' }]}
              >
                <InputBorderNone
                  placeholder="Edgar"
                  height={20}
                  className="bold-lith"
                />
              </Form.Item>

              <Form.Item
                label="Phone number"
                name="phone"
                className="phone"
                rules={[
                  { required: true, message: '' },
                  { min: 12, message: '' }
                ]}
              >
                <InputBorderNone
                  placeholder="329-230-2390"
                  height={20}
                  className="bold-lith"
                  onChange={formatterPhone}
                />
              </Form.Item>

              <Form.Item
                label="Email address"
                name="email"
                className="email"
                rules={[{ required: true, type: 'email', message: '' }]}
              >
                <InputBorderNone
                  type="email"
                  placeholder="ricardo@mervetscoolbiz.com"
                  height={20}
                  className="bold-lith"
                />
              </Form.Item>

              {response?.state !== TaskState.Approved &&
                <Form.Item className="submit" shouldUpdate>
                  {() => (
                    <ButtonSubmit
                      disabled={
                        !!response ||
                        !form.getFieldsError().every((fieldErr) => fieldErr.errors.length === 0) ||
                        !form.isFieldsTouched() ||
                        !Object.values(form.getFieldsValue()).every(value => value)
                      }
                      loading={fetching}
                      modalConfirm={{ show: true }}
                      onClick={form.submit}
                    >
                      Submit and complete task
                    </ButtonSubmit>
                  )}
                </Form.Item>
              }
            </Form>
          </div>
        </div>
        <div className='mt-20'>
          <b className="blue-bold cursor" style={{ fontWeight: 500 }}>
            I don’t have a title company. Give me one
          </b>{' '}
          <ArrowRightOutlined className="blue-bold cursor" />
        </div>

        {modalFinish &&
          <ModalFinish
            visible={modalFinish}
            setVisible={setModalFinish}
            closeTask={closeTask}
          />
        }
      </div>
    </section>
  );
};
export default Step1;
