import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { ExpandIcon, TaskOpenModalContext } from '../BorrowerTasks';
import { Loan, TaskResponse, TaskState } from '../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../services/task/querys';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import StateTag from '../components/Atoms/Tags/StateTag/StateTag';
import { LoanContext } from '../../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import { OpenTaskContext } from '../../../scenes/Private/LoanList/LoanList';

interface Props {
  task: any;
  visible?: boolean;
  handlerClose?: Function;
}

const InvestmentExperienceVerification = ({ task, visible, handlerClose }: Props) => {
  const loan: Loan | any = useContext(LoanContext);
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const { taskKey, setTaskKey }: any = useContext(OpenTaskContext);

  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [countProperties, setCountProperties] = useState<number>();
  const [response, setResponse] = useState<TaskResponse>();
  const [isReview, setIsReview] = useState(false);
  
  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    if (taskKey === 'investment_experience') {
      setModalTaskVisible(true);
      setTaskKey();
    } else if(!!taskKey)
      setModalTaskVisible(false);
  }, [taskKey]);

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
    setIsReview(_response?.state === TaskState.Review);
  }, [taskResponses]);

  useEffect(() => {
    setCountProperties(loan.investmentExperience.properties === '1-3' ? 2 : 3);
  }, [loan]);

  useEffect(() => {
    if (openContext === 'investment_experience') {
      setModalTaskVisible(true);
      setHandler();
    }
  }, [openContext]);

  const redirectTrackRecord = () => {
    setModalTaskVisible(false);
    setHandler('track_record');
  }

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const generateCircles: any = () => {
    return new Array(countProperties).fill(undefined).map((item, index) => {
      if (response?.files && response.files[index])
        return response.files[index].state;

      return 'draft';
    });
  };

  const generateCirclesFooter: any = () => {
    const filesCirclesStates = new Array(countProperties).fill(undefined).map((item, index) => {
      if (response?.files && response.files[index])
        return response.files[index].state;

      return 'draft';
    });

    const addAnotherDocs = taskResponses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || []; 
    return [...filesCirclesStates, ...docsStates];
  };

  return (
    <div className="InvestmentExperienceVerification">
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        header={{
          title: <span>UNDERWRITING | INVESTMENT EXPERIENCE VERIFICATION</span>,
          actionLeft: 
            isReview? <StateTag state={TaskState.Review} size="large"/>: <img src={iconCortPng} alt="icon" className="paperPng" />,
          actionRight: (
            <Button
              className="button button-transparent"
              onClick={() => onHelpDocs()}
            >
              <QuestionCircleOutlined />
              help docs
            </Button>
          )
        }}
        animationDirection="onRight"
        dots={false}
        stepsTotal={1}
        currentStep={1}
        setStep={null}
        footer={
          <div className="flex-btw footer_dost mt-10">
            <div className="column">
              <p className="h4 h4-w5">Property HUD status</p>
              <p className="body-regular">
                {response?.files?.length || 0}/{countProperties?.toString()} submitted
              </p>
            </div>
            <div className="column">
              <div className="circles">
                {generateCirclesFooter()?.map((state: string, index: number) => (
                  <div key={index} className={`circle circle-${state}`} />
                ))}
              </div>
            </div>
          </div>
        }
      >
        <Step1 
          countProperties={countProperties}
          generateCircles={generateCircles}
          redirectTrackRecord={redirectTrackRecord}
          response={response}
          setModalTaskVisible={setModalTaskVisible}
          task={task}
          closeTask={() => setModalTaskVisible(false)}
        />
      </ModalTask>
    </div>
  );
};
export default InvestmentExperienceVerification;
