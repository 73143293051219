import React, { useContext, useEffect, useState } from 'react';
import { Task, TaskState } from 'gql/graphql';
import CardOwnership from '../CardOwnership/CardOwnership';
import SectionIssues from '../../../SectionIssues/SectionIssues';
import { TaskContext } from '../EntityGuarantorCreation';

interface Step3Props {
  setStep: Function;
  closeTask: Function;
  responsesStep2?: any[];
}
const Step3 = ({ setStep, responsesStep2, closeTask }: Step3Props) => {
  const task: Task | any = useContext(TaskContext);

  const [ownerships, setOwnerships] = useState<any>([]);

  useEffect(() => {
    const _ownerships = [];

    if (responsesStep2 && responsesStep2?.length > 0) {
      for (const taskResponse of responsesStep2) {
        _ownerships.push({
          key: taskResponse._id,
          state: taskResponse.state,
          files: taskResponse.files,
          ...taskResponse.metadata,
          taskResponse
        });
      }
      setOwnerships(_ownerships);
    }
  }, [responsesStep2]);

  return (
    <section className="step-3">
      <div className="section-1">
        {ownerships && (
          <h3 className="h2">
            <span>Summary for Mervet’s Entity Ownership and Guarantors </span>
          </h3>
        )}

        <SectionIssues task={task}/>
        
        <div className="content-cards mt-30">
          {ownerships.map((ownership: any, index: number) => (
            <CardOwnership
              key={index}
              ownership={ownership}
              hiddenSectionViewProfile
              hiddenGuarantorTag
              hiddenTextEdit
              disabledForm={
                ownership.state !== TaskState.RequestError &&
                ownership.state !== TaskState.Draft
              }
              hiddenSubmitChanges={
                ownership.state !== TaskState.RequestError &&
                ownership.state !== TaskState.Draft
              }
              showTagState={ownership.state !== TaskState.Draft}
              createGuarantorState={TaskState.Review}
              isModalSubmit
              ownerships={ownerships}
              closeTask={closeTask}
              disabledPercentage
            />
          ))}
        </div>
      </div>
    </section>
  );
};
export default Step3;
