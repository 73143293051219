import React, { createContext, lazy, useContext, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';

import './LoanDetails.scss';
import { ReactComponent as IconMinimize } from 'assets/images/minimize.svg';
import iconDonwloadSvg from 'assets/images/icon-download.svg';
import { OpenTaskContext, StatusBadge } from '../LoanList';
import BorrowerTask, { ExpandIcon, EyeIcon } from '../../../../components/BorrowerTask/BorrowerTasks';
import AddNewTask from './AddNewTask/AddNewTask';
import { Loan, Task, TaskState } from '../../../../gql/graphql';
import { useLoanDetails } from './useLoanDetails';
import Progress from './Progress/Progress';
import { RulesTasksCards } from './RulesTask.constanst';
import { useDownloadFilePending } from '../../../../services/loan/querys';
import LoanDocuments from './LoanDocuments/LoanDocuments';
import { BorrowerCard } from './BorrowerCard/BorrowerCard';

const contactData = {
  label: 'Partners and contacts',
  content: [
    { field: 'Entity', value: <React.Fragment><i>Waiting for borrower</i> <span>{"Nudge ->"}</span></React.Fragment> },
    { field: 'Guarantors', value: <React.Fragment><i>Waiting for borrower</i> <span>{"Nudge ->"}</span></React.Fragment> },
    { field: 'Main loan contact', value: <React.Fragment>Ricardo Sims</React.Fragment> },
    { field: 'Title agent', value: <React.Fragment>Ricardo Sims <span>{"View info ->"}</span></React.Fragment> },
    { field: 'Insurance agent', value: <React.Fragment>Ricardo Sims <span>{"View info ->"}</span></React.Fragment> },
    { field: 'Payoff contact', value: <React.Fragment>Ricardo Sims <span>{"View info ->"}</span></React.Fragment> }
  ],
}
const contentBoxes = [
  {
    label: 'Loan Details',
    content: [
      { field: 'Loan Purpose', value: 'Refinance' },
      { field: 'Loan Type', value: '1 Year Fixed' },
      { field: 'Amortization', value: '1 Year Only' },
      { field: 'Lien', value: '1st Position' },
    ],
  },
  {
    label: 'Property Details',
    content: [
      { field: 'Property Type', value: 'Single-family' },
      { field: 'Unit count', value: <React.Fragment>12 <span>{"View unit info ->"}</span></React.Fragment> },
    ],
  },
  {
    label: 'Budget and valuation',
    content: [
      { field: 'As-is-value', value: '$200.000', extra: { field: 'Appraiser’s as-is-value', value: 'Awaiting appraisal' } },
      { field: 'Estimated ARV', value: '$300.000', extra: { field: 'Appraiser’s ARV', value: 'Awaiting appraisal' } },
      { field: 'Construction reserve', value: '$25.000' },
      { field: 'Rehab Budget', value: '$25.000' },
      { field: 'Purchase Price', value: '$25.000' },
      { field: 'Loan-to-cost', value: '$25.000' },
      { field: 'Advance amount', value: '$25.000' },
    ],
  },
  {
    label: 'Fees',
    content: [
      { field: 'Origination fee', value: '$2,000 (1.67%)' },
    ],
  },
];

interface ILoanDetailsProps {
  onClose: () => void;
}

export const LoanContext = createContext<Loan | {}>({});
export const TasksContext = createContext<Task[] | []>([]);
export const SelectLoanDocumentContext = createContext<{ loanDocKey: string; setLoanDocKey: any } | {}>({});

const LoanDetails: React.FC<ILoanDetailsProps> = ({ onClose }) => {

  const { setTaskKey }: any = useContext(OpenTaskContext);

  const { tasks, loanSelected } = useLoanDetails();

  const [phaseSelect, setPhaseSelect] = useState<string>('approval');
  const [loanDocSelect, setLoanDocSelect] = useState<string>();

  const getTasks = () => {
    const newTasks = tasks?.filter(task => !task.key);
    newTasks?.map((newTask: any) => {
      let isCard = RulesTasksCards.find(item => item.key === newTask._id);
      let _newTask = {
        key: newTask._id,
        modalKey: newTask._id,
        name: newTask.name,
        data: newTask,
        phaseProgress: newTask?.phase,
        state: newTask?.state
      };

      if (!isCard)
        RulesTasksCards.push(_newTask as any);
      else
        isCard.state = _newTask.state;
    })

    for (const rules of RulesTasksCards) {
      const findTask = tasks?.find(item => item?.key === rules.key);
      if (findTask) {
        if (findTask) rules.data = findTask as Task;
        rules.state = rules.state !== TaskState.Approved ? findTask?.state : rules.state;
      }
    }

    return RulesTasksCards;
  }

  if (!loanSelected)
    return null;

  return (
    <LoanContext.Provider value={loanSelected as Loan}>
      <TasksContext.Provider value={tasks as Task[]}>
        <SelectLoanDocumentContext.Provider value={{ loanDocKey: loanDocSelect, setLoanDocKey: setLoanDocSelect }}>
          <div className='loan-details'>
            <div className='loan-details-top-bar'>
              <div className='loan-info'>
                <span className='loan-borrower-name'>MERVET TAHA | </span>
                <span className='loan-address'>{loanSelected.name}</span>
              </div>
              <div className='close-button-container' onClick={onClose}>
                CLOSE X
              </div>
            </div>

            <div className='loan-details-first-row'>
              <div className='loan-progress'>
                <div className='loan-info'>
                  <span className='loan-status'>UNDERWRITING | </span>
                  {loanSelected?.onboarding?.estimateClosingDate &&
                    <span className='loan-closing-date'>Est. Closing:
                      <b>
                        {moment(loanSelected?.onboarding?.estimateClosingDate).format('MM/DD/YY')}
                      </b>
                    </span>
                  }
                </div>
                <div className='loan-address'>
                  {loanSelected.name}
                </div>
                <div className='loan-progress-section'>
                  <div className='loan-progress-items-container'>
                    <div className='loan-progress-item-caption'>
                      Loan Progress
                    </div>
                    <Progress
                      loan={loanSelected}
                      phaseSelect={phaseSelect}
                      rulesTasksCards={getTasks()}
                      handlerSetPhaseSelect={setPhaseSelect}
                      handlerSetTaskOpenModal={setTaskKey}
                    />
                  </div>
                </div>

                <div className='loan-values-summary'>
                  {loanSelected?.type}
                  {loanSelected?.purpose ? (' | ' + loanSelected?.purpose) : ''}
                  {loanSelected?.ltv ? (' | ' + loanSelected?.ltv + '% LTV') : ''}
                  {' '}
                  <span>{"Go to checklist -->"}</span>
                </div>
              </div>
              <div className='placeholder'></div>
              {loanSelected.owner &&
                <BorrowerCard {...loanSelected.owner} />
              }
            </div>

            <div className='loan-details-main'>
              <div className='left-column'>
                <div className='loan-summary'>
                  <div className='closing'>
                    <div className='label'>Est. Closing</div>
                    <div className='calendar'>
                      <div className='date'>
                        Sept. 2023
                      </div>
                      <div className='day'>
                        15
                      </div>
                    </div>
                    <div className='caption'>Closing <b>NOT</b> scheduled</div>
                  </div>
                  <ContentBox {...contactData} />
                </div>
                {/* <LoanOption {...loanOptionData} /> */}
                {contentBoxes.map((box) => (
                  <ContentBox
                    key={box.label}
                    label={box.label}
                    content={box.content}
                  />
                ))}
              </div><div className='right-column'>
                <BorrowerTasks />
                {tasks &&
                  <LoanDocuments tasks={tasks as Task[]} />
                }
                <div className='approve-loan'>
                  Approve loan
                </div>
              </div>
            </div>
          </div>
        </SelectLoanDocumentContext.Provider>
      </TasksContext.Provider>
    </LoanContext.Provider>
  );
};

/* Content Box */
type Item = { field: string, value: string | React.ReactNode, extra?: Item }
interface IContentBoxProps {
  label: string;
  content: Item[]
}

const ContentBox: React.FC<IContentBoxProps> = (props) => {
  const { label, content } = props;

  return (
    <div className='content-box'>
      <div className='label'>
        {label}
      </div>
      <div className='content'>
        {
          content.map(({ field, value, extra }, index) => (
            <div key={index} className='item'>
              <div className='item-row'>
                <div className='field'>{field}</div>
                <div className='value'>{value}</div>
              </div>
              {
                extra &&
                <div className='item-row extra'>
                  <div className='field'>{extra.field}</div>
                  <div className='value'>{extra.value}</div>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

/* Borrower Tasks */
interface IBorrowerTasksProps {
  // Define your props here
}

const BorrowerTasks: React.FC<IBorrowerTasksProps> = (props) => {

  const tasks: Task[] = useContext(TasksContext);
  const loan: any = useContext(LoanContext);

  const { data: filesPending } = useDownloadFilePending({
    variables: { loanId: loan._id }
  });

  const [filterSelected, setFilterSelected] = useState<string>('all');
  const [loadingDonwload, setLoadingDownload] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const createAndDownloadZip = async (taskId?: string, zipname?: string) => {
    if (!taskId)
      setLoadingDownload(true);

    const zip = new JSZip();

    if (filesPending) {
      const remoteFiles = filesPending?.map(async (file, index) => {
        if (!taskId || taskId === file.task._id) {
          try {
            const response = await fetch(file.url, { mode: 'cors' });
            const data = await response.blob();
            const fileName = `${index + 1}-${file.name}`; // Replace with a suitable file naming logic
            zip.file(fileName, data);
            return data;
          } catch (error) {
            console.error(`Failed to fetch ${file.url}`, error);
            return null;
          }
        }
      });

      const fetchedFiles = await Promise.all(remoteFiles);

      // Check if all fetches were successful
      if (fetchedFiles.every(file => file !== null)) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipname || 'files-in-review.zip');
          setLoadingDownload(false);
        });
      } else {
        console.error('Some files could not be fetched and were not added to the zip.');
        setLoadingDownload(false);
      }
    }
  };

  const downloadAll = () => createAndDownloadZip();

  const downloadFilesToTask = (taskId: string, taskName: string) => {
    createAndDownloadZip(taskId, `files-${taskName}.zip`);
  }

  const newTasks = tasks?.filter((item) => !item.key);
  const getCountState = (state: TaskState) => tasks?.filter(task => task.state === state)?.length;

  const filters = [
    { label: 'All', key: 'all', count: tasks?.length },
    { label: 'Incomplete', key: TaskState.Incomplete, count: getCountState(TaskState.Incomplete) },
    { label: 'Pending Review', key: TaskState.Review, count: getCountState(TaskState.Review) },
    { label: 'Rejected', key: TaskState.RequestError, count: getCountState(TaskState.RequestError) },
    { label: 'Approved', key: TaskState.Approved, count: getCountState(TaskState.Approved) },
  ];

  const tasksAdmin = tasks?.filter(item => !!item.key && item.key !== "property_access");

  return (
    <div className='borrower-tasks'>
      <div className='title'>
        Borrower Tasks
      </div>
      <AddNewTask />
      <div className='borrower-tasks-status-container'>
        {filters.map(filter => (
          <StatusBadge
            title={filter.label}
            count={filter.count}
            isSelected={filterSelected === filter.key}
            onClick={() => setFilterSelected(filter.key)}
          />
        ))}
      </div>
      <div className='borrower-tasks-actions'>
        {/*<div><EyeIcon /> VIEW DOC LIST</div>*/}
        <div onClick={downloadAll}>
          {loadingDonwload && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
          {!loadingDonwload && <img alt="download" src={iconDonwloadSvg} />}
          DOWNLOAD ALL
        </div>
        <div onClick={() => setExpandAll(!expandAll)}>
          {expandAll &&
            <>
              <IconMinimize />
              CLOSE EXPAND ALL
            </>
          }
          {!expandAll &&
            <>
              <ExpandIcon />
              EXPAND ALL
            </>
          }
        </div>
      </div>
      <div className='borrower-tasks-list'>
        {tasksAdmin?.map(task => task.key && (
          <BorrowerTask
            taskKey={task.key}
            label={task.name}
            filterSelected={filterSelected}
            onClickDownloadFiles={downloadFilesToTask}
            expandAll={expandAll}
          />
        ))}
        {newTasks?.map((item, index) => (
          <BorrowerTask
            key={index}
            taskKey={item._id}
            label={item.name}
            filterSelected={filterSelected}
            onClickDownloadFiles={downloadFilesToTask}
            expandAll={expandAll}
            isTaskNew />
        ))}
      </div>
    </div>
  );
};

export default LoanDetails;