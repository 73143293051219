import React, { useContext, useEffect, useState } from 'react';

import RightScenes from '../../../../components/RightScenes/RightScenes';
import { BorrowerDetailViewContext } from '../../Private';
import CardEntitiesAndLoans from './components/CardEntitiesAndLoans';
import CardProfile from './components/CardProfile';
import CardReport from './components/CardReport';
import { useGetBorrower } from '../../../../services/borrower/querys';

const BorrowerDetail = () => {

  const { visible: borrowerId, setHandlerVisible }: any = useContext(BorrowerDetailViewContext);

  const { data: borrower, fetching, reexecute: getBorrower } = useGetBorrower({
    variables: { borrowerId },
    pause: true
  });
  
  useEffect(() => {
    if (borrowerId)
      getBorrower({ requestPolicy: 'network-only' });
  }, [borrowerId])

  return (
    <RightScenes
      visible={!!borrowerId}
      handlerSetVisibile={() => setHandlerVisible(undefined)}
    >
      <div className='view-borrower-detail'>
        <div className='view-borrower-detail-header'>
          <h1 className='title'>
            <span>Borrower details</span>
          </h1>
        </div>

        <div className="flex-2-col">
          <div className="box">
            {/*<CardProfileNotifications />*/}
            {borrower && !fetching &&
              <>
                <CardEntitiesAndLoans entities={borrower.entities || []} loans={borrower.loans || []} />
                <CardReport
                  reportKey="credit_score"
                  title="Credit score"
                  borrower={borrower}
                  isCreditScore
                  buttons={{ download: 'Download report', upload: 'Upload new report' }}
                />
                <CardReport
                  reportKey="background_check"
                  title="Background check"
                  borrower={borrower}
                  buttons={{ download: 'Download background check', upload: 'Upload new background check' }}
                />
              </>
            }
          </div>
          <div className="box">
            {borrower && <CardProfile {...borrower} />}
            {/*<div className="flex-center">
              <Button className="button-r">
                BROKER
              </Button>
              <Button className="button-delete">
                if broker use this tag
              </Button>
            </div>*/}
          </div>
        </div>
      </div>
    </RightScenes>
  );
};

export default BorrowerDetail;
