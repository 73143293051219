import { useContext, useEffect, useState } from 'react';

import leafPng from 'assets/images/leaf.png';
import './NewTask.scss';
import { ExpandIcon } from '../BorrowerTasks';
import ModalTask from '../components/Organisms/Modals/ModalTask/ModalTask';
import CardUploadResponse from '../components/Atoms/Cards/CardUploadResponse/CardUploadResponse';
import { LoanContext } from '../../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import { Task, TaskResponse, TaskState } from '../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../services/task/querys';
import ActionAcceptAndReject from '../../ActionAcceptAndReject/ActionAcceptAndReject';
import SectionIssues from '../../SectionIssues/SectionIssues';
import AddAnotherDoc from '../components/Organisms/AddAnotherDoc/AddAnotherDoc';

interface Props {
  task: Task;
  visible?: boolean;
  handlerClose?: Function;
}

const NewTask = ({ task, visible, handlerClose }: Props) => {
  
  const loanSelected: any = useContext(LoanContext);

  const [response, setResponse] = useState<TaskResponse | any>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);

  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  useEffect(() => {
    if(!isModalTaskVisible && handlerClose)
      handlerClose();
  }, [isModalTaskVisible])

  useEffect(() => {
    setModalTaskVisible(!!visible);
  }, [visible])

  useEffect(() => {
    const _response = taskResponses?.find((item) => item.step === 1);
    setResponse(_response);
  }, [taskResponses]);

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      header={{
        title: <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastname} | {loanSelected.name}</span>,
        actionLeft: (<img src={leafPng} alt="icon" className="paperPng" />)
      }}
      animationDirection="onRight"
      dots={false}
      stepsTotal={1}
      currentStep={1}
      setStep={() => { }}
      footer={
        <></>
      }
    >
      <div className='NewTasks'>
        <section>
          <h2 className="h2">
            Upload a loan document for{' '}
            <span>{loanSelected.owner.name + ' ' + loanSelected.owner.lastname} {loanSelected.type}</span>
            {' '}loan in {loanSelected.name}
          </h2>

          <SectionIssues task={task} />

          <div className='mt-40'>
            <CardUploadResponse
              title={task.name}
              description={task.description || ''}
              limitFiles={1}
              submit={{
                title: 'Add to task',
                location: 'outside',
                hidden: true,
                loading: false,
                modalConfirm: true,
                confirmDescription:
                  "After submitting, you'll have to contact us to make any changes.",
                onClick: (files) => { }
              }}
              insertHtml={
                <ActionAcceptAndReject
                  taskResponseId={response?._id}
                  task={{ id: task._id, key: task.key, name: task.name }}
                  files={response?.files}
                  currentState={response?.state}
                />
              }
            />
          </div>

          <AddAnotherDoc task={task} />
        </section>
      </div>
    </ModalTask >
  );
};
export default NewTask;
