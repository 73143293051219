import React, { createContext, useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { ReactComponent as IconExport } from 'assets/images/icon-export.svg';
import { ReactComponent as IconMinimize } from 'assets/images/minimize.svg';
import { StatusBadge } from "../../scenes/Private/LoanList/LoanList";
import { useBorrowerTasks } from "./useBorrowerTasks";
import MinimumLiquidity, { TaskContext } from "./MinimumLiquidity/MinimumLiquidity";
import { Loan, Task, TaskState } from "../../gql/graphql";
import EntityGuarantorCreation from './EntityGuarantorCreation/EntityGuarantorCreation';
import TrackRecord from './TrackRecord/TrackRecord';
import EntityDocs from './EntityDocs/EntityDocs';
import InvestmentExperienceVerification from './InvestmentExperienceVerification/InvestmentExperienceVerification';
import ConstructionBudget from './ConstructionBudget/ConstructionBudget';
import TitleCompany from './TitleCompany/TitleCompany';
import Insurance from './Insurance/Insurance';
import Purchase from './Purchase/Purchase';
import Payoff from './Payoff/Payoff';
import PayAppraisal from './PayAppraisal/PayAppraisal';
import { LoanContext, SelectLoanDocumentContext } from '../../scenes/Private/LoanList/LoanDetails/LoanDetails';
import NewTask from './NewTask/NewTask';
import { useDeleteTask } from '../../services/task/mutations';
import { useGetTasksByLoan } from '../../services/task/querys';
import TaskProgress from './components/Atoms/Progress/TaskProgress/TaskProgress';

export const EyeIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M0.583496 7.00001C0.583496 7.00001 2.91683 2.33334 7.00016 2.33334C11.0835 2.33334 13.4168 7.00001 13.4168 7.00001C13.4168 7.00001 11.0835 11.6667 7.00016 11.6667C2.91683 11.6667 0.583496 7.00001 0.583496 7.00001Z" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 8.75C7.9665 8.75 8.75 7.9665 8.75 7C8.75 6.0335 7.9665 5.25 7 5.25C6.0335 5.25 5.25 6.0335 5.25 7C5.25 7.9665 6.0335 8.75 7 8.75Z" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const ExpandIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M8.75 1.75H12.25V5.25" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.25 12.25H1.75V8.75" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.2498 1.75L8.1665 5.83333" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.75 12.25L5.83333 8.16666" stroke="#6E7889" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const BellIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M12 5.33334C12 4.27247 11.5786 3.25505 10.8284 2.50491C10.0783 1.75476 9.06087 1.33334 8 1.33334C6.93913 1.33334 5.92172 1.75476 5.17157 2.50491C4.42143 3.25505 4 4.27247 4 5.33334C4 10 2 11.3333 2 11.3333H14C14 11.3333 12 10 12 5.33334Z" stroke="#2A2F3C" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.15335 14C9.03614 14.2021 8.86791 14.3698 8.6655 14.4864C8.46309 14.6029 8.2336 14.6643 8.00001 14.6643C7.76643 14.6643 7.53694 14.6029 7.33453 14.4864C7.13212 14.3698 6.96389 14.2021 6.84668 14" stroke="#2A2F3C" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const TrashIcon: React.FC = () => (
  <svg className="trash" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2 4H3.33333H14" stroke="#C73B36" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M12.6668 4V13.3333C12.6668 13.687 12.5264 14.0261 12.2763 14.2761C12.0263 14.5262 11.6871 14.6667 11.3335 14.6667H4.66683C4.31321 14.6667 3.97407 14.5262 3.72402 14.2761C3.47397 14.0261 3.3335 13.687 3.3335 13.3333V4M5.3335 4V2.66667C5.3335 2.31305 5.47397 1.97391 5.72402 1.72386C5.97407 1.47381 6.31321 1.33334 6.66683 1.33334H9.3335C9.68712 1.33334 10.0263 1.47381 10.2763 1.72386C10.5264 1.97391 10.6668 2.31305 10.6668 2.66667V4" stroke="#C73B36" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.6665 7.33334V11.3333" stroke="#C73B36" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.3335 7.33334V11.3333" stroke="#C73B36" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

interface IBorrowerTaskProps {
  label: string;
  taskKey: string;
  isReview?: boolean;
  isTaskNew?: boolean;
  filterSelected?: string;
  onClickDownloadFiles?: Function;
}

export const TaskOpenModalContext = createContext<{ value: string; setHandler: any } | {}>({});

const BorrowerTask: React.FC<IBorrowerTaskProps> = (props) => {

  const loanSelected: any = useContext(LoanContext);
  const { setLoanDocKey }: any = useContext(SelectLoanDocumentContext);

  const { label, taskKey, isTaskNew, filterSelected } = props;

  const [isExpand, setExpand] = useState<boolean>();
  const [taskOpen, setTaskOpen] = useState<string>();

  const { reexecute: getTasksByLoan } = useGetTasksByLoan({
    variables: { loanId: loanSelected?._id },
    pause: true
  });
  const { dataTask } = useBorrowerTasks({ taskKey });
  const { execute: deleteTask } = useDeleteTask();

  const stateName = (task: Task) => {
    if (task.state === TaskState.Draft) return 'NOT STARTED';
    else if (task.state === TaskState.Review && task.metadata.reviewRename)
      return task.metadata.reviewRename;
    else if (task.state === TaskState.Review) return 'PENDING REVIEW';
    else if (task.state === TaskState.RequestError) return 'ERROR';
    else if (task.state === TaskState.Approved) return 'COMPLETE';
    return task.state;
  }

  const onDeleteTaskNew = () => {
    if (dataTask?._id) {
      deleteTask({ taskId: dataTask?._id });
      getTasksByLoan({ requestPolicy: 'network-only' });
    }
  }

  const onClickOpenTask = () => {
    if (dataTask?.key) setTaskOpen(dataTask?.key);
    else if (dataTask?._id) setTaskOpen(dataTask?._id);
  }

  const handlerCloseTask = () => {
    setTaskOpen(undefined);
  }

  if (filterSelected !== 'all' && filterSelected !== dataTask?.state)
    return null;

  const tasksExpand = [
    'minimium_liquidity',
    'title_company',
    'insurance',
    'pay_appraisal',
  ];

  const viewExpand =
    (dataTask?.state === TaskState.Review || dataTask?.metadata?.reviewRename) &&
    tasksExpand.find(item => item === dataTask?.key);

  const tasks = [
    { key: 'minimium_liquidity', Component: MinimumLiquidity },
    { key: 'entity_guarantors', Component: EntityGuarantorCreation },
    { key: 'track_record', Component: TrackRecord },
    { key: 'entity_docs', Component: EntityDocs },
    { key: 'investment_experience', Component: InvestmentExperienceVerification },
    { key: 'construction_budget', Component: ConstructionBudget },
    { key: 'title_company', Component: TitleCompany },
    { key: 'insurance', Component: Insurance },
    { key: 'purchase', Component: Purchase },
    { key: 'payoff', Component: Payoff },
    { key: 'pay_appraisal', Component: PayAppraisal },
  ];

  return (
    <div className={'borrower-task-item ' + dataTask?.state}>
      <div className='row-1'>
        <div className='actions-container'>
          {/*<EyeIcon />*/}
          {props.onClickDownloadFiles &&
            <IconExport
              onClick={() =>
                props.onClickDownloadFiles && props.onClickDownloadFiles(dataTask?._id, dataTask?.name)
              }
            />
          }
        </div>
        <div className='label cursor' onClick={onClickOpenTask}>
          {label}
        </div>
        {!dataTask?.key /* Es una tarea creada */ &&
          <div className='feedback-actions-container'>
            <div onClick={onDeleteTaskNew}>
              <TrashIcon />
            </div>
          </div>
        }
        <div onClick={onClickOpenTask}>
          {dataTask && <StatusBadge title={stateName(dataTask)} />}
        </div>
        <div className="expand">
          {(dataTask?.key || dataTask?._id) &&
            <div
              onClick={() => setExpand(!isExpand)}
              style={{
                opacity: viewExpand ? 1 : 0.3,
                cursor: viewExpand ? 'pointer' : 'auto'
              }}
            >
              {isExpand && <IconMinimize />}
              {!isExpand && <ExpandIcon />}
            </div>
          }
          {loanSelected &&
            <>
              {tasks.map(task => (
                taskKey === task.key && dataTask &&
                <task.Component
                  task={dataTask}
                  visible={taskOpen === task.key}
                  handlerClose={handlerCloseTask}
                />
              ))}
              {isTaskNew && dataTask &&
                <NewTask
                  task={dataTask}
                  visible={taskOpen === dataTask?._id}
                  handlerClose={handlerCloseTask}
                />
              }
            </>
          }
        </div>
      </div>
      {isExpand && viewExpand === 'pay_appraisal' &&
        <div className="box-message-state-review">
          <span>Follow up with the borrower on the payment email:</span>
          <div>
            <b>{dataTask?.metadata?.userEmail}.</b>{' '}
            <Button type="link" onClick={() => setLoanDocKey('appraisal')}>
              Upload appraisal here <ArrowRightOutlined className="cursor" onClick={() => setLoanDocKey('appraisal')} />
            </Button>
          </div>
        </div>
      }
      {isExpand && viewExpand === 'title_company' &&
        <div className="box-message-state-review">
          <span>Follow up with the borrower:</span>
          <div>
            <Button type="link" onClick={() => setLoanDocKey('title')}>
              Upload appraisal here <ArrowRightOutlined className="cursor" onClick={() => setLoanDocKey('title')} />
            </Button>
          </div>
        </div>
      }
      {isExpand && viewExpand === 'insurance' &&
        <div className="box-message-state-review">
          <span>Follow up with the borrower:</span>
          <div>
            <Button type="link" onClick={() => setLoanDocKey('insurance')}>
              Upload appraisal here <ArrowRightOutlined className="cursor" onClick={() => setLoanDocKey('insurance')} />
            </Button>
          </div>
        </div>
      }
      {isExpand && viewExpand === 'minimium_liquidity' &&
        <div className='liquidity-info'>
          <TaskProgress
            title={<h4 className="h4 h4-w4 title">Minimum liquidity</h4>}
            price={<h4 className="body-card body-card-w5 max">$2MM</h4>}
            totalBalance={2000000}
            balances={dataTask?.metadata.totalBalance}
            colorBackground="#DFE4EB)"
          />
        </div>
      }
    </div>
  );
};

export default BorrowerTask;