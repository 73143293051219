import { gql } from "urql";

export const LOGIN: any = gql(`
    mutation login($loginUserInput: LoginUserInput!) {
        login(loginUserInput: $loginUserInput) {
            user {
                _id
                name
            }
            authToken
        }
    }
`)

export const GET_OAUTH_TOKEN_FOR_ADMIN: any = gql(`
    mutation getOAuthToken {
        getOAuthToken
    }
`)