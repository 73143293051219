import React, { useContext, useState } from "react";
import { Button, Input } from "antd";

import calendarPng from 'assets/images/calendar.png';

import InnerApproveRejectSection from "./components/InnerApprovalRejectSection/InnerApprovalRejectSection";
import ConfirmationButton from "./components/ConfirmationButton/ConfirmationButton";
import { ApprovalSectionTitle } from "./components/SectionTitle/SectionTitle";
import SelectSection from "./components/SelectSection/SelectSection";
import ModalEndSubmit from "../BorrowerTask/components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit";
import { useGetTaskResponsesByTask, useGetTasksByLoan } from "../../services/task/querys";
import { useApprovedResponse } from "../../services/task/mutations";
import { ModalTaskRightContext } from "../BorrowerTask/components/Organisms/Modals/ModalTask/ModalTask";
import { LoanContext } from "../../scenes/Private/LoanList/LoanDetails/LoanDetails";
import { Loan } from "../../gql/graphql";

interface ApprovalSectionProps {
  taskResponseId: string;
  taskId: string;
  borrowerName: string;
  taskName: string;
  dataOrDocuments: any[],
  minimumLiquidity?: any;
  onSubmit?: any;
}

const ApprovalSection: React.FC<ApprovalSectionProps> = (props) => {
  const loan: Loan | any = useContext(LoanContext);
  const { setHandler }: any = useContext(ModalTaskRightContext);
  const {
    taskId,
    taskResponseId,
    borrowerName,
    taskName,
    dataOrDocuments,
    minimumLiquidity,
    onSubmit: onSubmitRight
  } = props;

  const { data: tasks, reexecute: getTasksByLoan } = useGetTasksByLoan({
		variables: { loanId: loan?._id },
		pause: true
	});
  const { execute: approvedResponse } = useApprovedResponse();
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId } },
    pause: true
  });

  const [dataOrDocumentSelected, setDataOrDocumentSelected] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [approvalLoading, setApprovalLoading] = useState(false);
  const [approveEntireSection, setApproveEntireSection] = useState<string[] | null>();
  const isSubmitDisabled = dataOrDocumentSelected.length === 0;

  const handleApproval = async () => {
    setApprovalLoading(true);

    let documentsKeys = [];

    if (dataOrDocumentSelected.length === 1 && dataOrDocumentSelected[0] === 'Approve entire section')
      documentsKeys = dataOrDocuments?.map(item => item.key);
    else
      for (const documentName of dataOrDocumentSelected) {
        const document = dataOrDocuments.find(item => item.name === documentName);
        documentsKeys.push(document.key);
      }
      
    await approvedResponse({
      taskResponseId,
      dataOrDocumentsKeys: documentsKeys,
      metadata: {
        dataOrDocumentSelected,
      }
    });
    getTasksByLoan({ requestPolicy: 'network-only' });
    getTaskResponses({ requestPolicy: 'network-only' });
    setApprovalLoading(false);
    setShowModal(false);
    setHandler(false);
  }

  const optionsDataOrDocuments = () => {
    let options: any = [];
    if (dataOrDocuments?.length > 0)
      options = [...options, ...dataOrDocuments?.map(item => item.name)]
    else
      options.push('Approve entire section');
    return options;
  }

  return (
    <InnerApproveRejectSection
      hiddenMarkdown
      sectionAccount={
        <>
          {minimumLiquidity &&
            <div className="header-section">
              <span className="body-regular">Account:{' '}
                <b className="body-regular-bold">{minimumLiquidity.metadata?.nameAccount}</b>
              </span>
            </div>
          }
        </>
      }
      confirmationModal={
        <ModalEndSubmit
          visible={showModal}
          setVisible={setShowModal}
          modal={{
            title: 'Are you sure you want to approve these text sections and/or docs?',
            description: (
              <h4 className="h4 h4-w4">
                The borrower will be notified their task has been approved immediately.
              </h4>
            ),
          }}
          buttonSubmit={
            <Button
              className="button button-modal-ok"
              onClick={handleApproval}
              loading={approvalLoading}
            >
              Approve
            </Button>
          }
          className="modal-end-submit--white"
          icon={calendarPng}
        />
      }
      title={
        <ApprovalSectionTitle
          borrowerName={borrowerName}
          taskName={taskName}
        />
      }
      selectSectionContent={
        <React.Fragment>
          <SelectSection
            label="Data or document"
            placeholder="Select document(s) or data"
            options={optionsDataOrDocuments()}
            onSelect={(v) => { 
              setDataOrDocumentSelected(v); 
              setApproveEntireSection(undefined); 
            }}
            value={approveEntireSection}
            isMultiple
            optionEnd={
              dataOrDocuments?.length > 0 ?
                { 
                  label: 'Approve entire section', 
                  onClick: () => { 
                    setApproveEntireSection(optionsDataOrDocuments());
                    setDataOrDocumentSelected(optionsDataOrDocuments()); 
                  }
                }:
                undefined
            }
          />
        </React.Fragment>
      }
      submitButton={
        <ConfirmationButton
          text={"Approve"}
          disabledText={"Select data or document to approve"}
          isDisabled={isSubmitDisabled}
          isFetching={false}
          onClick={() => setShowModal(true)}
        />
      }
    />
  )
}


export default ApprovalSection;