import React from 'react';
import './TopBar.scss';

interface ITopBarProps {
  // define your props here
}

const TopBar: React.FC<ITopBarProps> = (props) => {
  // use your props here

  return (
    <div className="top-bar">
      <div className='logo'>
        <ConstlendingLogo />
      </div>
      <div className="internal">
        INTERNAL
      </div>
      <div className='button-blue-outlined'>
        Create New Borrower Loan
      </div>
    </div>
  );
};

const ConstlendingLogo: React.FC = () => (
  <div className="constlending-logo">
    <img className="constlending-logo-blue" src="/assets/icons/constlending-blue.svg" alt="logo" />
    <img className="constlending-logo-text" src="/assets/icons/constlending-text.svg" alt="logo" />
  </div>
)

export default TopBar;