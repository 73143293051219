import { useMutation } from 'urql';
import { LOGIN, GET_OAUTH_TOKEN_FOR_ADMIN } from './graphql';

export const useLogin = (query?: any) => {
    const [{ data, fetching, error }, execute] = useMutation((query || LOGIN));
    return { execute, data, fetching, error };
}

export const useGetOAuthToken = (query?: any) => {
    const [{ data, fetching, error }, execute] = useMutation((query || GET_OAUTH_TOKEN_FOR_ADMIN));
    return { execute, data, fetching, error };
}